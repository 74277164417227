<template>
  <v-dialog id="dialogForm" v-model="dialog" width="100%">
    <template v-slot:activator="{ on }">
      <v-btn
        rounded
        elevation="0"
        style="height: 30px"
        color="primary"
        v-on="on"
        @click="(dialog = true), fetchConsentDefault()"
      >
        <v-icon v-if="false" style="margin-right: 5px" size="14px"
          >$edit</v-icon
        >
        {{ $t("edit", { name: "" }) }}</v-btn
      >
    </template>
    <v-row class="ma-0">
      <v-card class="" style="width: 100%"
        ><v-card-title
          style="text-decoration: underline; text-transform: uppercase"
        >
          {{ $t("consent-form.consent_default_name", { name: service.name }) }}
        </v-card-title>
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <base-material-card id="baseCons" class="base">
          <form
            action
            data-vv-scope="consent-form"
            @submit.prevent="validateForm('consent-form')"
            id="basePier"
          >
            <h2>ESTUDIO</h2>
            <div class="borderRound pa-4">
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.studio_name") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="errors.collect('consent-form.studio_name')"
                    data-vv-name="studio_name"
                    v-model="form.studio.studio_name"
                    class="text-field"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.address") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="errors.collect('consent-form.address')"
                    data-vv-name="address"
                    v-model="form.studio.address"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.phone") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="errors.collect('consent-form.phone')"
                    data-vv-name="phone"
                    type="number"
                    v-on:keypress="isNumber($event)"
                    v-model="form.studio.phone"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="4" lg="3"
                  ><label>{{ $t("consent-form.email") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|email'"
                    :error-messages="errors.collect('consent-form.email')"
                    data-vv-name="email"
                    v-model="form.studio.email"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label style="min-width: 200px !important"
                    >{{ $t("consent-form.sanitary_auth_number") }}:
                  </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect('consent-form.sanitary_auth')
                    "
                    data-vv-name="sanitary_auth"
                    type="number"
                    v-on:keypress="isNumber($event)"
                    v-model="form.customer.sanitary_auth"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <PopUpFirma
                    :form="form"
                    name="estudioSignature1"
                    :text="$t('consent-form.estudio_signature')"
                  ></PopUpFirma>
                </v-col>
              </v-row>
            </div>
            <h2>ARTISTA</h2>
            <div class="borderRound pa-4">
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.tattooer_name") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="
                      errors.collect('consent-form.tattoer_first_name')
                    "
                    data-vv-name="tattoer_first_name"
                    v-model="form.tattooer.user.first_name"
                  >
                  </v-text-field></v-col
                ><v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.tattooer_last_name") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="
                      errors.collect('consent-form.tattoer_last_name')
                    "
                    data-vv-name="tattoer_last_name"
                    v-model="form.tattooer.user.last_name"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.tattooer_dni") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="errors.collect('consent-form.tattoer_dni')"
                    data-vv-name="tattoer_dni"
                    v-model="form.tattooer.dni"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <PopUpFirma
                    :form="form"
                    name="tattooerSignature1"
                    :text="$t('consent-form.tattooer_signature')"
                  ></PopUpFirma>
                </v-col>
              </v-row>
            </div>
            <h2>SERVICIO</h2>

            <!--PIERCING-->
            <div v-if="service.name === 'piercer'" class="borderRound pa-4">
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.body_piercer_part") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required|min:2'"
                    :error-messages="errors.collect('consent-form.body_part')"
                    data-vv-name="body_part"
                    v-model="form.body_part"
                  ></v-text-field
                  ><!--Se debería hacer un items de todas las partes del cuerpo, para así seleccionar la que necesites--></v-col
                >

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.planned_sessions") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect('consent-form.planned_sessions')
                    "
                    data-vv-name="planned_sessions"
                    v-model="form.planned_sessions"
                    type="number"
                    v-on:keypress="isNumber($event)"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.actual_session") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect('consent-form.actual_session')
                    "
                    data-vv-name="actual_session"
                    v-model="form.actual_session"
                    type="number"
                    v-on:keypress="isNumber($event)"
                  >
                  </v-text-field
                ></v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3"
                  ><label>{{ $t("consent-form.anestesia") }}:</label
                  ><v-select
                    :items="items"
                    single-line
                    dense
                    outlined
                    hide-details
                    v-model="form.anestesia"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="errors.collect('consent-form.anestesia')"
                    data-vv-name="anestesia"
                  ></v-select
                ></v-col>
                <v-col cols="12" md="3"
                  ><label>{{ $t("consent-form.name_anestesia") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    :disabled="form.anestesia == 0 || form.anestesia == null"
                    data-vv-validate-on="blur"
                    v-validate="
                      form.anestesia || form.anestesia == 1
                        ? 'required|min:2'
                        : ''
                    "
                    :error-messages="
                      errors.collect('consent-form.name_anestesia')
                    "
                    data-vv-name="name_anestesia"
                    v-model="form.name_anestesia"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <!--Tabla tinta/colores...-->
              <v-radio-group
                v-model="form.type_form"
                class="pl-2 mt-0 mb-3"
                data-vv-name="type_form"
                :error-messages="
                  errors.collect('inventorymovements-form.type_form')
                "
                data-vv-validate-on="blur"
                v-validate="'required'"
              >
                <v-radio color=" primary">
                  <template v-slot:label
                    ><div
                      style="
                        margin-top: 6px;
                        width: 100%;
                        white-space: normal !important;
                      "
                    >
                      {{ $t("consent-form.option_a") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio color="primary" class="mt-2">
                  <template v-slot:label
                    ><div
                      style="
                        margin-top: 6px;
                        width: 100%;
                        white-space: normal !important;
                      "
                    >
                      {{ $t("consent-form.option_b") }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <v-row v-if="form.type_form === 0">
                <v-col class="pt-0 pb-6">
                  <v-simple-table width="650">
                    <thead align="center">
                      <tr>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.piercing_used") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.instrument_used") }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item, i) in form.inktable" :key="i">
                        <td v-if="i == 0">
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.piercer_name"
                            data-vv-validate-on="blur"
                            v-validate="'required'"
                            :error-messages="
                              errors.collect('consent-form.ink_name_' + i)
                            "
                            :data-vv-name="'ink_name_' + i"
                          ></v-text-field>
                        </td>
                        <td v-else></td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.instrument"
                            data-vv-validate-on="blur"
                            v-validate="'required'"
                            :error-messages="
                              errors.collect('consent-form.colors_' + i)
                            "
                            :data-vv-name="'colors_' + i"
                          ></v-text-field>
                        </td>

                        <td>
                          <v-btn icon @click="del(i)"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="1">
                          <v-btn
                            @click="add"
                            color="primary"
                            elevation="0"
                            style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t("consent-form.add_row") }}
                          </v-btn>
                        </td>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </v-col>
              </v-row>
              <!--Tabla material/utensilios...-->
              <v-row v-else-if="form.type_form === 1">
                <v-col class="pt-0 pb-6">
                  <v-simple-table width="650">
                    <thead align="center">
                      <tr>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.description") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.sterilization_date") }}
                        </th>
                        <th class="text-center" min-width="120">
                          {{ $t("consent-form.expire_date") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.num_reuses") }}
                        </th>
                        <th class="text-center" style="min-width: 110px">
                          {{ $t("consent-form.traceability") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in form.materialtable" :key="i">
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.description"
                            data-vv-validate-on="blur"
                            v-validate="'required'"
                            :error-messages="
                              errors.collect('consent-form.description_' + i)
                            "
                            :data-vv-name="'description_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.sterilization_date"
                            data-vv-validate-on="blur"
                            v-validate="'required'"
                            :error-messages="
                              errors.collect(
                                'consent-form.sterilization_date_' + i
                              )
                            "
                            :data-vv-name="'sterilization_date_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.expire_date"
                            data-vv-validate-on="blur"
                            v-validate="'required'"
                            :error-messages="
                              errors.collect(
                                'consent-form.expire_date_material_' + i
                              )
                            "
                            :data-vv-name="'expire_date_material_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.num_reuses"
                            data-vv-validate-on="blur"
                            v-validate="'required'"
                            :error-messages="
                              errors.collect('consent-form.num_reuses_' + i)
                            "
                            :data-vv-name="'num_reuses_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.traceability"
                            data-vv-validate-on="blur"
                            v-validate="'required'"
                            :error-messages="
                              errors.collect('consent-form.traceability_' + i)
                            "
                            :data-vv-name="'traceability_' + i"
                          ></v-text-field>
                        </td>

                        <td>
                          <v-btn icon @click="delMaterial(i)"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="1">
                          <v-btn
                            @click="addMaterial"
                            color="primary"
                            elevation="0"
                            style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t("consent-form.add_row") }}
                          </v-btn>
                        </td>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.session_duration") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="errors.collect('consent-form.duration')"
                    data-vv-name="duration"
                    v-model="form.session.duration"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.cicatrization_time") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect('consent-form.cicatrization_time')
                    "
                    data-vv-name="cicatrization_time"
                    v-model="form.session.cicatrization_time"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.budget") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect('consent-form.price_budget')
                    "
                    data-vv-name="price_budget"
                    v-model="form.session.price_budget"
                    type="number"
                    v-on:keypress="isNumber($event)"
                    append-icon="mdi-currency-eur"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="7"
                  ><label
                    :style="
                      $vuetify.breakpoint.xsOnly
                        ? 'line-height: 1.5;display: block;white-space: normal; margin-bottom: 9.5px'
                        : ''
                    "
                    >{{ $t("consent-form.piercer_measures") }}:</label
                  >

                  <v-textarea
                    style="border-radius: 10px"
                    outlined
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect('consent-form.healing_measures')
                    "
                    data-vv-name="healing_measures"
                    v-model="form.session.healing_measures"
                    rows="2"
                  >
                  </v-textarea
                ></v-col>
              </v-row>
            </div>
            <!--TATTOOER-->
            <div v-if="service.name === 'tattooer'" class="borderRound pa-4">
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.body_part") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'min:2'"
                    :error-messages="errors.collect('consent-form.body_part')"
                    data-vv-name="body_part"
                    v-model="form.body_part"
                  ></v-text-field
                  ><!--Se debería hacer un items de todas las partes del cuerpo, para así seleccionar la que necesites--></v-col
                >

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.planned_sessions") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.planned_sessions')
                    "
                    data-vv-name="planned_sessions"
                    v-model="form.planned_sessions"
                    type="number"
                    v-on:keypress="isNumber($event)"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.actual_session") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.actual_session')
                    "
                    data-vv-name="actual_session"
                    v-model="form.actual_session"
                    type="number"
                    v-on:keypress="isNumber($event)"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <!--Tabla tinta/colores...-->
              <v-row>
                <v-col class="pt-0 pb-6">
                  <v-label>{{ $t("consent-form.inks") }}</v-label>
                  <v-simple-table width="650">
                    <thead align="center">
                      <tr>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.ink_used_name") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.colours") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.used_lot_number") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.registry") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.code") }}
                        </th>
                        <th class="text-center" min-width="120">
                          {{ $t("consent-form.expire_date") }}
                        </th>
                        <th class="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in form.inktable" :key="i">
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.ink_name"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.ink_name_' + i)
                            "
                            :data-vv-name="'ink_name_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.colors"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.colors_' + i)
                            "
                            :data-vv-name="'colors_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.used_lot"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.used_lot_' + i)
                            "
                            :data-vv-name="'used_lot_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.registry"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.registry_' + i)
                            "
                            :data-vv-name="'registry_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.code"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.code_' + i)
                            "
                            :data-vv-name="'code_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.expire_date"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.expire_date_' + i)
                            "
                            :data-vv-name="'expire_date_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-btn icon @click="del(i)"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="1">
                          <v-btn
                            @click="add"
                            color="primary"
                            elevation="0"
                            style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t("consent-form.add_row") }}
                          </v-btn>
                        </td>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </v-col>
              </v-row>
              <!--Tabla material/utensilios...-->
              <v-row>
                <v-col class="pt-0 pb-6">
                  <v-label>{{ $t("consent-form.material") }}</v-label>
                  <v-simple-table width="650">
                    <thead align="center">
                      <tr>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.description") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.sterilization_date") }}
                        </th>
                        <th class="text-center" min-width="120">
                          {{ $t("consent-form.expire_date") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.num_reuses") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.traceability") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in form.materialtable" :key="i">
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.description"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.description_' + i)
                            "
                            :data-vv-name="'description_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.sterilization_date"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect(
                                'consent-form.sterilization_date_' + i
                              )
                            "
                            :data-vv-name="'sterilization_date_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.expire_date"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect(
                                'consent-form.expire_date_material_' + i
                              )
                            "
                            :data-vv-name="'expire_date_material_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.num_reuses"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.num_reuses_' + i)
                            "
                            :data-vv-name="'num_reuses_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.traceability"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.traceability_' + i)
                            "
                            :data-vv-name="'traceability_' + i"
                          ></v-text-field>
                        </td>

                        <td>
                          <v-btn icon @click="delMaterial(i)"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="1">
                          <v-btn
                            @click="addMaterial"
                            color="primary"
                            elevation="0"
                            style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t("consent-form.add_row") }}
                          </v-btn>
                        </td>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.session_duration") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="errors.collect('consent-form.duration')"
                    data-vv-name="duration"
                    v-model="form.session.duration"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.cicatrization_time") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.cicatrization_time')
                    "
                    data-vv-name="cicatrization_time"
                    v-model="form.session.cicatrization_time"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.budget") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.price_budget')
                    "
                    data-vv-name="price_budget"
                    v-model="form.session.price_budget"
                    type="number"
                    v-on:keypress="isNumber($event)"
                    append-icon="mdi-currency-eur"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="7"
                  ><label
                    :style="
                      $vuetify.breakpoint.xsOnly
                        ? 'line-height: 1.5;display: block;white-space: normal; margin-bottom: 9.5px'
                        : ''
                    "
                    >{{ $t("consent-form.tattooer_measures") }}:</label
                  >

                  <v-textarea
                    style="border-radius: 10px"
                    outlined
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.healing_measures')
                    "
                    data-vv-name="healing_measures"
                    v-model="form.session.healing_measures"
                    rows="2"
                  >
                  </v-textarea
                ></v-col>
              </v-row>
            </div>
            <!--LASER-->
            <div v-if="service.name === 'laser'" class="borderRound pa-4">
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.body_laser_part") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'min:2'"
                    :error-messages="errors.collect('consent-form.body_part')"
                    data-vv-name="body_part"
                    v-model="form.body_part"
                  ></v-text-field
                  ><!--Se debería hacer un items de todas las partes del cuerpo, para así seleccionar la que necesites--></v-col
                >

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.planned_sessions") }}: </label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.planned_sessions')
                    "
                    data-vv-name="planned_sessions"
                    v-model="form.planned_sessions"
                    type="number"
                    v-on:keypress="isNumber($event)"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="3"
                  ><label>{{ $t("consent-form.actual_session") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.actual_session')
                    "
                    data-vv-name="actual_session"
                    v-model="form.actual_session"
                    type="number"
                    v-on:keypress="isNumber($event)"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row v-if="false">
                <v-col cols="12" md="3"
                  ><label>{{ $t("consent-form.anestesia") }}:</label
                  ><v-select
                    :items="items"
                    single-line
                    dense
                    outlined
                    hide-details
                    v-model="form.anestesia"
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="errors.collect('consent-form.anestesia')"
                    data-vv-name="anestesia"
                  ></v-select
                ></v-col>
                <v-col cols="12" md="3"
                  ><label>{{ $t("consent-form.name_anestesia") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    :disabled="form.anestesia == 0 || form.anestesia == null"
                    data-vv-validate-on="blur"
                    v-validate="
                      form.anestesia || form.anestesia == 1 ? 'min:2' : ''
                    "
                    :error-messages="
                      errors.collect('consent-form.name_anestesia')
                    "
                    data-vv-name="name_anestesia"
                    v-model="form.name_anestesia"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <!--Tabla tinta/colores...-->
              <v-radio-group
                v-if="false"
                v-model="form.type_form"
                class="pl-2 mt-0 mb-3"
                data-vv-name="type_form"
                :error-messages="
                  errors.collect('inventorymovements-form.type_form')
                "
                data-vv-validate-on="blur"
                v-validate="''"
              >
                <v-radio color=" primary">
                  <template v-slot:label
                    ><div
                      style="
                        margin-top: 6px;
                        width: 100%;
                        white-space: normal !important;
                      "
                    >
                      {{ $t("consent-form.option_a") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio color="primary" class="mt-2">
                  <template v-slot:label
                    ><div
                      style="
                        margin-top: 6px;
                        width: 100%;
                        white-space: normal !important;
                      "
                    >
                      {{ $t("consent-form.option_b") }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <v-row v-if="false">
                <v-col class="pt-0 pb-6">
                  <v-simple-table width="650">
                    <thead align="center">
                      <tr>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.piercing_used") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.instrument_used") }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item, i) in form.inktable" :key="i">
                        <td v-if="i == 0">
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.piercer_name"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.ink_name_' + i)
                            "
                            :data-vv-name="'ink_name_' + i"
                          ></v-text-field>
                        </td>
                        <td v-else></td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.instrument"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.colors_' + i)
                            "
                            :data-vv-name="'colors_' + i"
                          ></v-text-field>
                        </td>

                        <td>
                          <v-btn icon @click="del(i)"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="1">
                          <v-btn
                            @click="add"
                            color="primary"
                            elevation="0"
                            style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t("consent-form.add_row") }}
                          </v-btn>
                        </td>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </v-col>
              </v-row>
              <!--Tabla material/utensilios...-->
              <v-row v-if="false">
                <v-col class="pt-0 pb-6">
                  <v-simple-table width="650">
                    <thead align="center">
                      <tr>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.description") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.sterilization_date") }}
                        </th>
                        <th class="text-center" min-width="120">
                          {{ $t("consent-form.expire_date") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.num_reuses") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.traceability") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in form.materialtable" :key="i">
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.description"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.description_' + i)
                            "
                            :data-vv-name="'description_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.sterilization_date"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect(
                                'consent-form.sterilization_date_' + i
                              )
                            "
                            :data-vv-name="'sterilization_date_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.expire_date"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect(
                                'consent-form.expire_date_material_' + i
                              )
                            "
                            :data-vv-name="'expire_date_material_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.num_reuses"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.num_reuses_' + i)
                            "
                            :data-vv-name="'num_reuses_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.traceability"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.traceability_' + i)
                            "
                            :data-vv-name="'traceability_' + i"
                          ></v-text-field>
                        </td>

                        <td>
                          <v-btn icon @click="delMaterial(i)"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="1">
                          <v-btn
                            @click="addMaterial"
                            color="primary"
                            elevation="0"
                            style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t("consent-form.add_row") }}
                          </v-btn>
                        </td>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.session_duration") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="errors.collect('consent-form.duration')"
                    data-vv-name="duration"
                    v-model="form.session.duration"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.cicatrization_time") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.cicatrization_time')
                    "
                    data-vv-name="cicatrization_time"
                    v-model="form.session.cicatrization_time"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.budget") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.price_budget')
                    "
                    data-vv-name="price_budget"
                    v-model="form.session.price_budget"
                    type="number"
                    v-on:keypress="isNumber($event)"
                    append-icon="mdi-currency-eur"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="7"
                  ><label
                    :style="
                      $vuetify.breakpoint.xsOnly
                        ? 'line-height: 1.5;display: block;white-space: normal; margin-bottom: 9.5px'
                        : ''
                    "
                    >{{ $t("consent-form.piercer_measures") }}:</label
                  >

                  <v-textarea
                    style="border-radius: 10px"
                    outlined
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.healing_measures')
                    "
                    data-vv-name="healing_measures"
                    v-model="form.session.healing_measures"
                    rows="2"
                  >
                  </v-textarea
                ></v-col>
              </v-row>
            </div>
            <!--MICROBLADING-->
            <div
              v-if="service.name === 'microblading'"
              class="borderRound pa-4"
            >
              <v-row>
                <v-col class="pt-0 pb-6">
                  <v-label>{{ $t("consent-form.inks") }}</v-label>
                  <v-simple-table width="650">
                    <thead align="center">
                      <tr>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.ink_used_name") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.colours") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.used_lot_number") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.registry") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.code") }}
                        </th>
                        <th class="text-center" min-width="120">
                          {{ $t("consent-form.expire_date") }}
                        </th>
                        <th class="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in form.inktable" :key="i">
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.ink_name"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.ink_name_' + i)
                            "
                            :data-vv-name="'ink_name_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.colors"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.colors_' + i)
                            "
                            :data-vv-name="'colors_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.used_lot"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.used_lot_' + i)
                            "
                            :data-vv-name="'used_lot_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.registry"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.registry_' + i)
                            "
                            :data-vv-name="'registry_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.code"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.code_' + i)
                            "
                            :data-vv-name="'code_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.expire_date"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.expire_date_' + i)
                            "
                            :data-vv-name="'expire_date_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-btn icon @click="del(i)"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="1">
                          <v-btn
                            @click="add"
                            color="primary"
                            elevation="0"
                            style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t("consent-form.add_row") }}
                          </v-btn>
                        </td>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </v-col>
              </v-row>
              <!--Tabla material/utensilios...-->
              <v-row>
                <v-col class="pt-0 pb-6">
                  <v-label>{{ $t("consent-form.material") }}</v-label>
                  <v-simple-table width="650">
                    <thead align="center">
                      <tr>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.description") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.sterilization_date") }}
                        </th>
                        <th class="text-center" min-width="120">
                          {{ $t("consent-form.expire_date") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.num_reuses") }}
                        </th>
                        <th class="text-center" min-width="100">
                          {{ $t("consent-form.traceability") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in form.materialtable" :key="i">
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.description"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.description_' + i)
                            "
                            :data-vv-name="'description_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.sterilization_date"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect(
                                'consent-form.sterilization_date_' + i
                              )
                            "
                            :data-vv-name="'sterilization_date_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.expire_date"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect(
                                'consent-form.expire_date_material_' + i
                              )
                            "
                            :data-vv-name="'expire_date_material_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.num_reuses"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.num_reuses_' + i)
                            "
                            :data-vv-name="'num_reuses_' + i"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            :hide-details="$vuetify.breakpoint.xs"
                            v-model="item.traceability"
                            data-vv-validate-on="blur"
                            v-validate="''"
                            :error-messages="
                              errors.collect('consent-form.traceability_' + i)
                            "
                            :data-vv-name="'traceability_' + i"
                          ></v-text-field>
                        </td>

                        <td>
                          <v-btn icon @click="delMaterial(i)"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="1">
                          <v-btn
                            @click="addMaterial"
                            color="primary"
                            elevation="0"
                            style="
                              width: 120px !important;
                              color: #363533;
                              height: 25px;
                              padding-top: 10px !important;
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t("consent-form.add_row") }}
                          </v-btn>
                        </td>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.autokey_brand") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.autokey_brand')
                    "
                    data-vv-name="autokey_brand"
                    v-model="form.autokey_brand"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.autokey_model") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.autokey_model')
                    "
                    data-vv-name="autokey_model"
                    v-model="form.autokey_model"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.autokey_reference") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.autokey_reference')
                    "
                    data-vv-name="autokey_reference"
                    v-model="form.autokey_reference"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.autokey_control_q") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.autokey_control_q')
                    "
                    data-vv-name="autokey_control_q"
                    v-model="form.autokey_control_q"
                  >
                  </v-text-field
                ></v-col>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.autokey_control_b") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.autokey_control_b')
                    "
                    data-vv-name="autokey_control_b"
                    v-model="form.autokey_control_b"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.autokey_control_m") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.autokey_control_m')
                    "
                    data-vv-name="autokey_control_m"
                    v-model="form.autokey_control_m"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.session_duration") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="errors.collect('consent-form.duration')"
                    data-vv-name="duration"
                    v-model="form.session.duration"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.cicatrization_time") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.cicatrization_time')
                    "
                    data-vv-name="cicatrization_time"
                    v-model="form.session.cicatrization_time"
                  >
                  </v-text-field
                ></v-col>

                <v-col class="py-0" cols="12" sm="6" md="4"
                  ><label>{{ $t("consent-form.budget") }}:</label>
                  <v-text-field
                    :hide-details="$vuetify.breakpoint.xs"
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.price_budget')
                    "
                    data-vv-name="price_budget"
                    v-model="form.session.price_budget"
                    type="number"
                    v-on:keypress="isNumber($event)"
                    append-icon="mdi-currency-eur"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="6" md="7"
                  ><label
                    :style="
                      $vuetify.breakpoint.xsOnly
                        ? 'line-height: 1.5;display: block;white-space: normal; margin-bottom: 9.5px'
                        : ''
                    "
                    >{{ $t("consent-form.piercer_measures") }}:</label
                  >

                  <v-textarea
                    style="border-radius: 10px"
                    outlined
                    data-vv-validate-on="blur"
                    v-validate="''"
                    :error-messages="
                      errors.collect('consent-form.healing_measures')
                    "
                    data-vv-name="healing_measures"
                    v-model="form.session.healing_measures"
                    rows="2"
                  >
                  </v-textarea
                ></v-col>
              </v-row>
            </div>

            <div class="pa-3 text-right">
              <v-btn
                @click="saveConsentDefault"
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                elevation="0"
              >
                <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                {{ $t("save", { name: $t("") }) }}
              </v-btn>
            </div>
          </form>
        </base-material-card>
      </v-card>
    </v-row>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ConsentFormCard",
  props: ["service"],
  components: {
    CardConf: () => import("@/components/user/settings/Card"),
    EditForm: () =>
      import("@/components/user/settings/profile/EditConsentForm"),
    PopUpFirma: () => import("@/components/ui/PopUpFirma"),
  },
  mounted() {
    console.log(this.$store.state.auth.user.studio);
    this.form = this.$store.state.auth.user.studio.consent_form_piercer;
    if (this.form === null) {
      this.form = {
        customer: {
          legal_tutor: { tutor_type: "father" },
          user: {},
          medical_report: false,
          minus16_incapacitated: false,
          attached_information: false,
        },
        tattooer: { user: {} },
        studio: { user: {}, timetable: [[[]]] },
        available_dates: [{}],
        design: { photos: [], urls: [] },
        inktable: [],
        materialtable: [],
        session: { treatment_tips: false },
        estudioSignature1: null,
        clientSignature1: null,
        clientSignature2: null,
        tattooerSignature1: null,
      };
    }
  },

  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },

    ...mapActions("consent_form_default", [
      "addConsentFormDefault",
      "getConsentFormDefault",
    ]),
    saveConsentDefault() {
      console.log("SERVICE_ID", this.service.id);
      console.log("FORM", this.form);
      this.addConsentFormDefault({
        service_id: this.service.id,
        form: this.form,
      }).then((data) => {
        //if (data.success) {
        this.dialog = false;
        this.$alert(this.$t("save_ok"));
        //}
      });
    },

    fetchConsentDefault() {
      console.log("FETCH CONSENT DEFAULT");
      this.getConsentFormDefault({
        service_id: this.service.id,
        user_id: "me",
      }).then((data) => {
        console.log("DATA", data);
        if (data.success) {
          this.form = data.data.consent_form_default;
        }
      });
    },

    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
    },

    saveConsentForm() {
      this.$emit("validated", this.form);
      this.form = this.$store.state.auth.user.studio.consent_form_piercer;
    },

    del(index) {
      //console.log("delete", index);
      this.form.inktable.splice(index, 1);
    },
    delMaterial(index) {
      //console.log("delete", index);
      this.form.materialtable.splice(index, 1);
    },
    add() {
      this.form.inktable.push({});
    },
    addMaterial() {
      this.form.materialtable.push({});
    },
  },
  data() {
    return {
      disabled: false,
      form: null,
      services: [],
      service_id: null,
      type_form: null,
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
      dialog: false,
    };
  },
  computed: {
    consentForm_edit: {
      get() {
        return this.$store.getters["auth/getSetting"]("consentForm_edit");
      },
      set(value) {
        //llamamos a la funcion de crear setting
        this.settingUpdate({
          key: "consentForm_edit",
          value,
        }).then((data) => {});
      },
    },
  },
};
</script>
<style lang="sass">
#basePier
  .row
    width: 100% !important
    margin-left: 0 !important
  .v-data-table
    table
      min-width: 650px
</style>
